import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "aesthet-nova, Helvetica, Arial, sans-serif",
    body: "aktiv-grotesk, Arial, sans-serif",
  },
  colors: {
    primary: {
      50: "#fff7e2",
      100: "#ffebb5",
      200: "#ffde86",
      300: "#ffd257",
      400: "#ffc737",
      500: "#febe23",
      600: "#fdb01f",
      700: "#fd9e1d",
      800: "#fc8d1b",
      900: "#fb6e18",
    },
    secondary: {
      50: "#777777",
      100: "#666666",
      200: "#555555",
      300: "#444444",
      400: "#333333",
      500: "#222222",
      600: "#111111",
      700: "#000000",
      800: "#000000",
      900: "#000000",
    },
    secondaryAlpha: {
      50: "rgba(34, 34, 34, 0.08)",
      100: "rgba(34, 34, 34, 0.16)",
      200: "rgba(34, 34, 34, 0.36)",
      300: "rgba(34, 34, 34, 0.64)",
      400: "rgba(34, 34, 34, 0.82)",
      500: "rgba(34, 34, 34, 1)",
      600: "rgba(17, 17, 17, 1)",
      700: "rgba(0, 0, 0, 1)",
      800: "rgba(0, 0, 0, 1)",
      900: "rgba(0, 0, 0, 1)",
    },
    whiteAlpha: {
      50: "rgba(255, 255, 255, 0.08)",
      100: "rgba(255, 255, 255, 0.16)",
      200: "rgba(255, 255, 255, 0.36)",
      300: "rgba(255, 255, 255, 0.64)",
      400: "rgba(255, 255, 255, 0.82)",
      500: "rgba(255, 255, 255, 1)",
      600: "rgba(255, 255, 255, 1)",
      700: "rgba(255, 255, 255, 1)",
      800: "rgba(255, 255, 255, 1)",
      900: "rgba(255, 255, 255, 1)",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "1000px",
      },
      variants: {
        "outline": {
          borderWidth: "2px",
        }
      }
    }
  },
})

export default theme;